import { defineStore } from 'pinia';
import { getDictionaryFromCX } from '@/api/dictionary';
import { DictionaryState, IDictionaryItem, IFlatDictionaryItem } from './types';
import {
  DICTIONARY_STATUS_ENUM,
  MP_DICTIONARY_KEY,
} from '@/constant/dictionary';
import { QUERY_SEARCH_TYPE } from '@/modules/miaokuan-manage/brand/constant';
import {
  getAttributeOptions,
  getBrandOptions,
  getSelectOptions,
  getShowCityOptions,
} from '@/modules/miaokuan-manage/brand/api';
import { getCategoryOptions } from '@/modules/resource-manage/domestic-goods/api';
import { IBrandShowCityReq } from '@/modules/miaokuan-manage/brand/api/type.d';

export const useDictionaryStore = defineStore({
  id: 'BrandDtlDict',
  state: (): DictionaryState => ({
    cxDictionaryMap: {},
  }),
  actions: {
    async getCxDictionary(codeList: string[], senderCode?: string, params?:IBrandShowCityReq) {
      try {
        const dictionaryKeys = Object.keys(this.cxDictionaryMap);
        let notIncludedCodeList = codeList.filter((key) => {
          return !dictionaryKeys.includes(key);
        });
        if (!notIncludedCodeList.length) return;
        const customKeys: string[] = [];
        const keys = Object.keys(MP_DICTIONARY_KEY);
        notIncludedCodeList = notIncludedCodeList.filter((key) => {
          const flag = keys.includes(key);
          if (flag) {
            customKeys.push(key);
          }
          return !flag;
        });
        customKeys.forEach(async (key) => {
          let data:IDictionaryItem[] = [];
          let res;
          let result:IDictionaryItem[] = []; // 存放结果集
          switch (key) {
            case MP_DICTIONARY_KEY.CATEGORY_SPU: {
              res = await getCategoryOptions();
              const itemMap: {
                [key: string]: IDictionaryItem;
              } = {};
              res.data.forEach((item) => {
                const id = item.valueCode;
                const pid = item.valueParentCode;
                if (!itemMap[id]) {
                  itemMap[id] = {
                    label: '',
                    value: '',
                    // children: [],
                    disabled: false,
                  };
                }
                const obj: IDictionaryItem = {
                  label: item.value,
                  value: item.valueCode,
                  disabled: false,
                };
                if (itemMap[id].children) {
                  obj.children = itemMap[id].children;
                }
                itemMap[id] = obj;
                const treeItem = itemMap[id];
                if (pid === '') {
                  result.push(treeItem);
                } else {
                  if (!itemMap[pid]) {
                    itemMap[pid] = {
                      label: '',
                      value: '',
                      disabled: false,
                    };
                  }
                  if (itemMap[pid]?.children) {
                    itemMap[pid].children?.push(treeItem);
                  } else {
                    itemMap[pid].children = [treeItem];
                  }
                }
              });
              data = result;
              break;
            }
            case MP_DICTIONARY_KEY.MOKEN_BRAND_TYPE:
            case MP_DICTIONARY_KEY.MOKEN_BRAND_AREA: {
              res = await getSelectOptions({
                dictCode: QUERY_SEARCH_TYPE[key],
              });
              result = res.data.map((item) => {
                return {
                  label: item.name,
                  value: item.value,
                  disabled: false,
                };
              });
              data = result;
              break;
            }
            case MP_DICTIONARY_KEY.MOKEN_BRAND_NAME: {
              res = await getBrandOptions();
              result = res.data.map((item) => {
                return {
                  label: item.brandName,
                  value: item.id,
                  disabled: false,
                };
              });
              data = result;
              break;
            }
            case MP_DICTIONARY_KEY.YEAR_SEASON: {
              res = await getAttributeOptions({
                dictCode: QUERY_SEARCH_TYPE[key],
              });
              result = res.data.map((item) => {
                return {
                  label: item.name,
                  value: item.value,
                  disabled: false,
                };
              });
              data = result;
              break;
            }
            case MP_DICTIONARY_KEY.MOKEN_BRAND_CITY: {
              res = await getShowCityOptions({
                brandName: params?.brandName,
              });
              result = res.data.map((item) => {
                return {
                  label: item.name,
                  value: item.value,
                  disabled: false,
                };
              });
              data = result;
              break;
            }

            default:
              break;
          }
          this.cxDictionaryMap[key] = data;
        });
        if (notIncludedCodeList.length === 0) return;
        const { data } = await getDictionaryFromCX({
          dictCodes: notIncludedCodeList,
          senderCode,
        });
        data.forEach((items) => {
          const result:IDictionaryItem[] = []; // 存放结果集
          const itemMap: {
            [key: string]: IDictionaryItem;
          } = {};
          items.dictValues.forEach((item) => {
            const id = item.valueCode;
            const pid = item.valueParentCode;
            if (!itemMap[id]) {
              itemMap[id] = {
                label: '',
                value: '',
                // children: [],
                disabled: false,
              };
            }
            const obj: IDictionaryItem = {
              label: item.value,
              value: item.valueCode,
              disabled: item.isEnable === DICTIONARY_STATUS_ENUM.DISABLE,
            };
            if (itemMap[id].children) {
              obj.children = itemMap[id].children;
            }
            itemMap[id] = obj;
            const treeItem = itemMap[id];
            if (pid === '') {
              result.push(treeItem);
            } else {
              if (!itemMap[pid]) {
                itemMap[pid] = {
                  label: '',
                  value: '',
                  disabled: false,
                };
              }
              if (itemMap[pid]?.children) {
                itemMap[pid].children?.push(treeItem);
              } else {
                itemMap[pid].children = [treeItem];
              }
            }
          });
          this.cxDictionaryMap[items.dictCode] = result;
        });
      } catch (e) {
        console.error(e, '获取字典数据接口异常');
      }
    },
    // 扁平化字典
    flatCxDictionary(code: string) {
      const deep = (
        arr: IDictionaryItem[],
        result: IFlatDictionaryItem[],
        pIds: string[],
        cIds: string[],
        lastLevelChildIds: string[],
      ) => {
        arr.forEach((v) => {
          const currentChildIds: string[] = [];
          const currentLastLevelChildIds: string[] = [];
          if (v.children && v.children.length) {
            deep(v.children, result, [...pIds, v.value], currentChildIds, currentLastLevelChildIds);
          } else {
            lastLevelChildIds.push(v.value);
          }
          cIds.push(v.value, ...currentChildIds);
          lastLevelChildIds.push(...currentLastLevelChildIds);
          result.push({
            ...v,
            pIds: [...pIds],
            cIds: [...currentChildIds],
            lastLevelChildIds: [...currentLastLevelChildIds],
          });
        });
      };
      if (!this.cxDictionaryMap[code]) return [];
      const result:IFlatDictionaryItem[] = [];
      deep(this.cxDictionaryMap[code], result, [], [], []);
      return result;
    },
  },
});
