import { defineComponent } from 'vue';

export default defineComponent({
  emits: [
    'update:rangeStart',
    'update:rangeEnd',
  ],
  props: {
    /**
     * 最大值
     */
    min: {
      type: Number,
      default: -Infinity,
    },
    /**
     * 最大值
     */
    max: {
      type: Number,
      default: Infinity,
    },
    /**
     * 小数点位数
     */
    precision: {
      type: Number,
      default: Infinity,
    },
    rangeStart: {
      type: [String, Number],
      required: true,
    },
    rangeEnd: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleChangeStart = (val: string|number) => {
      console.log(val);
      emit('update:rangeStart', val);
    };
    const handleChangeEnd = (val: string|number) => {
      emit('update:rangeEnd', val);
    };
    return {
      handleChangeEnd,
      handleChangeStart,
    };
  },
  render() {
    return (
      <el-row>
        <el-col span={10}>
          <input-number
            min={this.min}
            max={this.max}
            precision={this.precision}
            modelValue={this.rangeStart}
            onChange={this.handleChangeStart}
            placeholder="请输入"
          />
        </el-col>
        <el-col span={1} style={{ textAlign: 'center' }}>~</el-col>
        <el-col span={10}>
          <input-number
            min={this.min}
            max={this.max}
            precision={this.precision}
            modelValue={this.rangeEnd}
            onChange={this.handleChangeEnd}
            placeholder="请输入"
          />
        </el-col>
        <el-col span={3} style={{ textAlign: 'center' }}>{this.unit}</el-col>
      </el-row>
    );
  },
});
