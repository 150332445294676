import { computed, ref, Ref } from 'vue';
import { SEARCH_BASE_SETTING_ENUM } from '@/modules/miaokuan-manage/common/constant';
import type { ISearchConfigItem } from '../type.d';
import { ICustomParams } from '../type.d';
import { BRAND_TAB_ENUMS } from '@/modules/miaokuan-manage/brand/constant';
import { MP_DICTIONARY_KEY } from '@/constant/dictionary';
import { useDictionaryStore } from '../dictionary';
import { IDictionaryItem } from '@/store/dictionary/types';
import { useRoute } from 'vue-router';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
  currentTab:Ref<BRAND_TAB_ENUMS>,
  categoryAttrs: Ref<IDictionaryItem[]>,
) => {
  const route = useRoute();
  const brandName = route.query.brandName as string;
  const dictionaryStore = useDictionaryStore();
  const cascaderConfig = ref({
    multiple: true,
  });
  // 秀场城市
  const SHOW_CITY_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.MOKEN_BRAND_CITY] || []);
  // 品类
  const DESIGN_CATEGORY_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.CATEGORY_SPU] || []);
  // 年份季节
  const YEAR_SEASON_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.YEAR_SEASON] || []);
  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_BASE_SETTING_ENUM.CITY,
        render: () => {
          return (
          <virtual-select
            label="城市"
            v-model={params.value.city}
            disabled={currentTab.value !== BRAND_TAB_ENUMS.SHOW}
            options={SHOW_CITY_OPTIONS.value}
          >
            {SHOW_CITY_OPTIONS.value.map(v => (
              <el-option value={v.value} label={v.label} />
            ))}
          </virtual-select>
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.CATEGORY,
        render: () => {
          return (
            <custom-cascader
              label="品类"
              options={DESIGN_CATEGORY_OPTIONS.value}
              v-model={params.value.categoryClass}
              show-all-levels={false}
              props={cascaderConfig.value}
              disabled={[BRAND_TAB_ENUMS.SHOW, BRAND_TAB_ENUMS.INS].includes(currentTab.value)}
              onChange={() => {
                params.value.categoryAttr = [];
              }}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.ATTRS,
        render: () => {
          return (
            <custom-cascader
              label="属性"
              options={categoryAttrs.value}
              v-model={params.value.categoryAttr}
              disabled={[BRAND_TAB_ENUMS.SHOW, BRAND_TAB_ENUMS.INS].includes(currentTab.value)}
              show-all-levels={false}
              props={cascaderConfig.value}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.YEAR_SEASON,
        render: () => {
          return (
            <virtual-select
              label="年份季节"
              v-model={params.value.yearSeason}
              disabled={[BRAND_TAB_ENUMS.SHOW, BRAND_TAB_ENUMS.INS].includes(currentTab.value)}
              multiple
              options={YEAR_SEASON_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.PUBLISH_TIME,
        render: () => {
          return (
            <custom-date-picker
              label="发布时间"
              v-model={params.value.publishTime}
            >
            </custom-date-picker>
          );
        },
      },
    ];
    if (categoryAttrs.value.length === 0) {
      const index = baseConfig.findIndex(v => v.key === SEARCH_BASE_SETTING_ENUM.ATTRS);
      baseConfig.splice(index, 1);
    }
    return baseConfig;
  });
  const getOptions = () => {
    dictionaryStore.getCxDictionary([
      MP_DICTIONARY_KEY.MOKEN_BRAND_CITY,
      MP_DICTIONARY_KEY.YEAR_SEASON,
      MP_DICTIONARY_KEY.CATEGORY_SPU,
    ], undefined, { brandName });
  };
  return {
    searchConfig,
    getOptions,
  };
};
