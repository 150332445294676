import { has } from '@/core/plugins/filter';
import { computed } from 'vue';

const usePermissionConfig = () => {
  return {
    /* 品牌详情-秀场 */
    SHOW: computed(() => has('MOKEN-SJZX-MK-PPXQ-SHOW')),
    /* 品牌详情-淘宝 */
    TB: computed(() => has('MOKEN-SJZX-MK-PPXQ-TB')),
    /* 品牌详情-京东 */
    JD: computed(() => has('MOKEN-SJZX-MK-PPXQ-JD')),
    /* 品牌详情-INS */
    INS: computed(() => has('MOKEN-SJZX-MK-PPXQ-INS')),
    /* 品牌详情-唯品会 */
    WPH: computed(() => has('MOKEN-SJZX-MK-PPXQ-WPH')),
  };
};

export default usePermissionConfig;
