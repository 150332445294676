// 商品状态
export enum GOODS_STATE_ENUM {
  /* 在售 */
  IN_SALE = '1',
  /* 下架 */
  OFF_SALE = '2',
  /* 预售 */
  BOOKING = '3',
  /* 售罄 */
  SALE_OUT = '4',
}
export const GOODS_STATE_LIST = [
  { label: '在售', value: GOODS_STATE_ENUM.IN_SALE },
  { label: '预售', value: GOODS_STATE_ENUM.BOOKING },
  { label: '下架', value: GOODS_STATE_ENUM.OFF_SALE },
  { label: '售罄', value: GOODS_STATE_ENUM.SALE_OUT },
];

export enum SEARCH_BASE_SETTING_ENUM{
  /* 品类 */
  CATEGORY = 'categoryClass',
  /* 风格 */
  STYLE = 'style',
  /* 裙型 */
  SKIRT_TYPE = 'skirtType',
  /* SPU */
  SPU = 'spu',
  /* SKC */
  SKC = 'skc',
  /* 商品名称 */
  SKC_NAME = 'skcName',
  /* 设计元素 */
  DESIGN_ELEMENT = 'designElements',
  /* 系列 */
  SERIES = 'series',
  /* 设计裁剪 */
  DESIGN_CUTTING = 'designTailoring',
}

export const SEARCH_BASE_SETTING_LIST = [
  { label: '品类', value: SEARCH_BASE_SETTING_ENUM.CATEGORY },
  { label: '风格', value: SEARCH_BASE_SETTING_ENUM.STYLE },
  { label: '裙型', value: SEARCH_BASE_SETTING_ENUM.SKIRT_TYPE },
  { label: 'SPU', value: SEARCH_BASE_SETTING_ENUM.SPU },
  { label: 'SKC', value: SEARCH_BASE_SETTING_ENUM.SKC },
  { label: '商品名称', value: SEARCH_BASE_SETTING_ENUM.SKC_NAME },
  { label: '设计元素', value: SEARCH_BASE_SETTING_ENUM.DESIGN_ELEMENT },
  { label: '系列', value: SEARCH_BASE_SETTING_ENUM.SERIES },
  { label: '设计裁剪', value: SEARCH_BASE_SETTING_ENUM.DESIGN_CUTTING },
];

export enum SEARCH_SALE_SETTING_ENUM{
  /* 渠道类型 */
  CHANNLE_TYPE = 'channelType',
  /* 渠道名称 */
  CHANNLE_NAME = 'channelName',
  /* 品牌 */
  BRAND_NAME = 'brandName',
  /* 店铺 */
  STORE_NAME = 'storeName',
  /* 商品状态 */
  COMMODITY_STATUS = 'commodityStatus',
  /* 是否新品 */
  IS_NEW = 'isNew',
  /* 零售价 */
  ORIGIN_PRICE = 'originPrice',
  /* 优惠价 */
  DISCOUNT_PRICE = 'discountPrice',
  /* 券后价 */
  COUPON_PRICE = 'couponPrice',
  /* 折扣率 */
  DISCOUNT_RATE = 'discountRate',
  /* 上架日期 */
  SHELF_DATE = 'shelfDate',
  /* 下架日期 */
  OFF_DATE = 'offDate',
  /* 在架日期 */
  DAYS_ON_SHELF = 'daysOnShelf',
  /* 发售日期 */
  START_TIME = 'startingTime',
  /* 销售天数 */
  SALES_DAYS = 'salesDays',
  /* 总销量 */
  SPU_TOTAL_SALES = 'spuTotalSales',
  /* 价格 */
  SPU_COUPON_PRICE = 'spuCouponPrice',
}

export const SEARCH_SALE_SETTING_LIST = [
  { label: '渠道类型', value: SEARCH_SALE_SETTING_ENUM.CHANNLE_TYPE },
  { label: '渠道名称', value: SEARCH_SALE_SETTING_ENUM.CHANNLE_NAME },
  { label: '品牌', value: SEARCH_SALE_SETTING_ENUM.BRAND_NAME },
  { label: '店铺', value: SEARCH_SALE_SETTING_ENUM.STORE_NAME },
  { label: '商品状态', value: SEARCH_SALE_SETTING_ENUM.COMMODITY_STATUS },
  { label: '是否新品', value: SEARCH_SALE_SETTING_ENUM.IS_NEW },
  { label: '零售价', value: SEARCH_SALE_SETTING_ENUM.ORIGIN_PRICE },
  { label: '优惠价', value: SEARCH_SALE_SETTING_ENUM.DISCOUNT_PRICE },
  { label: '券后价', value: SEARCH_SALE_SETTING_ENUM.COUPON_PRICE },
  { label: '折扣率', value: SEARCH_SALE_SETTING_ENUM.DISCOUNT_RATE },
  { label: '上架日期', value: SEARCH_SALE_SETTING_ENUM.SHELF_DATE },
  { label: '下架日期', value: SEARCH_SALE_SETTING_ENUM.OFF_DATE },
  { label: '在架天数', value: SEARCH_SALE_SETTING_ENUM.DAYS_ON_SHELF },
  { label: '发售日期', value: SEARCH_SALE_SETTING_ENUM.START_TIME },
  { label: '在售天数', value: SEARCH_SALE_SETTING_ENUM.SALES_DAYS },
];

export enum QUERY_SEARCH_TYPE {
  /* 抖音店铺 */
  STORE_NAME = 'moken_dy_store_name',
  /* 商品状态 */
  COMMODITY_STATUS = 'moken_commodity_status',
  /* 系列 */
  SERIES = '2',
  /* 风格 */
  STYLE = '6',
}

// 渠道来源
export enum CHANNLE_SOURCE_ID_ENUM {
  /* 国内电商平台 */
  DOMESTIC_PLATFORM = '1',
  /* 国内独立站 */
  DOMESTIC_PRIVATE = '2',
  /* 国外独立站 */
  FOREIGN_PRIVATE = '3',
  /* 国外电商平台 */
  FOREIGN_PLATFORM = '4',
}

export const CHANNLE_SOURCE_ID_LIST = [
  { label: '国内电商平台', value: CHANNLE_SOURCE_ID_ENUM.DOMESTIC_PLATFORM },
  { label: '国内独立站', value: CHANNLE_SOURCE_ID_ENUM.DOMESTIC_PRIVATE },
  { label: '国外电商平台', value: CHANNLE_SOURCE_ID_ENUM.FOREIGN_PLATFORM },
  { label: '国外独立站', value: CHANNLE_SOURCE_ID_ENUM.FOREIGN_PRIVATE },
];

// 渠道来源
export enum CHANNLE_SOURCE_NAME_ENUM {
  /* 国内电商平台 */
  DOMESTIC_PLATFORM = '国内电商平台',
  /* 国内独立站 */
  DOMESTIC_PRIVATE = '国内独立站',
  /* 国外独立站 */
  FOREIGN_PRIVATE = '国外独立站',
  /* 国外电商平台 */
  FOREIGN_PLATFORM = '国外电商平台',
}

export const CHANNLE_SOURCE_NAME_LIST = [
  { label: '国内电商平台', value: CHANNLE_SOURCE_NAME_ENUM.DOMESTIC_PLATFORM },
  { label: '国内独立站', value: CHANNLE_SOURCE_NAME_ENUM.DOMESTIC_PRIVATE },
  { label: '国外电商平台', value: CHANNLE_SOURCE_NAME_ENUM.FOREIGN_PLATFORM },
  { label: '国外独立站', value: CHANNLE_SOURCE_NAME_ENUM.FOREIGN_PRIVATE },
];

export enum GOODS_IS_NEW_ENUM {
  /* 是 */
  YES = '1',
  /* 否 */
  NO = '0',
}

export const GOODS_IS_NEW_LIST = [
  { label: '是', value: GOODS_IS_NEW_ENUM.YES },
  { label: '否', value: GOODS_IS_NEW_ENUM.NO },
];

export enum SORT_WORD_ENUM {
  /* 默认 */
  DEFAULT = '',
  /* 总销量 */
  SPU_TOTAL_SALES = 'spuTotalSales',
  /* 评论数 */
  COMMENT_CNT = 'commentCnt',
}

export const SORT_WORD_LIST = [
  // { value: SORT_WORD_ENUM.DEFAULT, label: '默认' },
  { value: SORT_WORD_ENUM.SPU_TOTAL_SALES, label: '总销量' },
  { value: SORT_WORD_ENUM.COMMENT_CNT, label: '总评论数' },
];

export enum SORT_TYPE_ENUM {
  /* 正序 */
  TOP = 'ascending',
  /* 倒序 */
  DOWN = 'descending',
}

// 获取下拉选项的枚举
export enum OPTION_ENUM {
  CHANNEL_TYPE = 1,
  SHOP_TYPE = 2,
  CATEGORY_TYPE = 3,
  STORE_NAME_DOMESTIC = 6,
}

export enum QUERY_SEARCH_ATTRIBUTE {
  /* 风格 */
  STYLE1 = 'style',
  /* 年份季节 */
  YEAR_SEASON = 'year_season',
  /* 版型 */
  CLOTHING_MODEL = 'clothing_model',
  /* 厚薄 */
  THICKNESS = 'thickness',
  /* 图案 */
  PATTERN = 'pattern',
  /* 面料材质 */
  FABRIC = 'fabric',
}
