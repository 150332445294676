import slsLogger from '@/core/plugins/slsLogger/SLSLogger';
import { EVENT_TYPE_ENUM } from '@/core/plugins/slsLogger/constant/index';

// 商品曝光上报
export function trackingExposure<T>(
  list: T[],
  key: keyof T,
  opt?: {[key: string]: string;},
) {
  if (list.length > 0) {
    slsLogger.send({
      eventType: EVENT_TYPE_ENUM.EXPOSURE,
      eventDetails: {
        eventName: '商品曝光量',
        ...opt,
        goodList: list.map(item => item[key]),
      },
    });
  }
}

// 点击上报
export function trackingClick<T>(
  eventDetails: {[key: string]: T;},
) {
  slsLogger.send({
    eventType: EVENT_TYPE_ENUM.CLICK,
    eventDetails,
  });
}
