
import { computed, defineComponent, PropType } from 'vue';
import { numberfuns } from 'cx-utils';
import { BRAND_TAB_ENUMS } from '@/modules/miaokuan-manage/brand/constant';
import TaoBaoImg from '@/assets/images/brand/icon-taobao.png';
import JingDongImg from '@/assets/images/brand/icon-jingdong.png';
import WeiPinImg from '@/assets/images/brand/icon-weipin.png';
import { IGoodsItem } from '@/modules/miaokuan-manage/brand/views/detail/type.d';

export default defineComponent({
  emits: ['select-img', 'image'],
  props: {
    data: {
      type: Object as PropType<IGoodsItem>,
      default: () => ({}),
    },
    currentTab: {
      type: String as PropType<BRAND_TAB_ENUMS>,
      default: BRAND_TAB_ENUMS.SHOW,
    },
  },
  setup(props, { emit }) {
    /* eslint-disable no-restricted-globals */
    const intValidator = (num: string | number) => {
      const Nnum = Number(num);
      if (isNaN(Nnum) || Nnum < 0) return 0;
      if (Nnum < 10000) return Nnum;
      const Fnum = +numberfuns.formatFloat(Nnum / 10000, { pos: 1, round: true });
      return `${Fnum} w`;
    };

    const headImgByType = computed(() => {
      const tab = props.currentTab;
      if (tab === BRAND_TAB_ENUMS.JD) return JingDongImg;
      if (tab === BRAND_TAB_ENUMS.TB) return TaoBaoImg;
      return WeiPinImg;
    });

    const handleSelectImgItem = (row: IGoodsItem, type: 'quick'|'normal' = 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      emit(
        'select-img',
        {
          id,
          key: row.id,
          categoryClass: row?.categoryClass2Name || row?.categoryClass1Name,
          channelId: row.channelId,
        },
        type,
      );
    };

    const handleImageItem = (row: IGoodsItem) => {
      emit('image', row);
    };

    return {
      headImgByType,
      BRAND_TAB_ENUMS,
      handleImageItem,
      handleSelectImgItem,
      intValidator,
    };
  },
});
