import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import {
  IGetDictionaryByCodeReq,
  IGetDictionaryByCodeRes,
} from './types';

// 创新 获取字典数据
export const getDictionaryFromCX = (data: IGetDictionaryByCodeReq) => {
  const url = '/ufg/web/v1/dict-value/batch-list';
  return http.post<IGetDictionaryByCodeRes[]>({
    url,
    data,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
  });
};
