import router from '@/router';

interface IndexObject {
  [propName: string]: any;
}
// router query 参数处理
function handleObject(val: string) {
  try {
    return JSON.parse(decodeURIComponent(val));
  } catch (e) {
    return val;
  }
}
function handleNumber(val: string) {
  return Number(val);
}
function handleBoolean(val: string) {
  return Boolean(val);
}
function handleString(val: string) {
  return decodeURIComponent(val);
}

const strats = {
  object: handleObject,
  number: handleNumber,
  boolean: handleBoolean,
  string: handleString,
};

type stratsKey = 'object' | 'number' | 'boolean' | 'string';

export function processRouterQueryParams<T = any>(origin: IndexObject): T {
  const { query } = router.currentRoute.value;
  const newQuery: IndexObject = {};
  Object.keys(origin).forEach((key) => {
    const val = query[key];
    if (val) {
      newQuery[key] = strats[typeof origin[key] as stratsKey](val as any);
    }
  });
  return Object.assign({}, origin, newQuery) as T;
}

export function updateRouteUrl(query: IndexObject = {}) {
  query = Object.assign({}, query);
  // 调用 this.$router.replace 路由重复时，报 Navigation Duplicated
  query.t = +new Date();
  // 编码中文
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (typeof value === 'object') {
      query[key] = encodeURIComponent(JSON.stringify(value));
    } else {
      query[key] = value ? encodeURIComponent(value) : '';
    }
  });
  router.replace({
    name: router.currentRoute.value.name as string,
    query,
  });
}
