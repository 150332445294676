// 创新字典key
export enum CX_DICTIONARY_KEY{
  /* 风格 */
  DESIGN_STYLE = 'design_style',
  /* 品类 */
  DESIGN_CATEGORY = 'design_category',
}

export enum CX_CUSTOM_DICTIONARY_KEY{
  /* 裙型 */
  SKIRT_TYPE = 'SKIRT_TYPE',
  /* 设计元素 */
  DESIGN_ELEMENT = 'DESIGN_ELEMENT',
  /* 系列 */
  SERIES = 'SERIES',
  /* 设计裁剪 */
  DESIGN_TAILORING = 'DESIGN_TAILORING',
  /* 店铺 */
  STORE_NAME = 'STORE_NAME',
  /* 品牌 */
  BRAND_NAME = 'BRAND_NAME',
  /* 分类 */
  CATEGORY = 'CATEGORY',
  /* 渠道 */
  CHANNLE_SOURCE = 'CHANNLE_SOURCE',
  // 品类-SPU
  CATEGORY_SPU = 'CATEGORY_SPU',
  // 风格
  STYLE = 'STYLE',
  // 风格:通过属性字典接口获取
  STYLE1 = 'STYLE1',
  // 年份季节
  YEAR_SEASON = 'YEAR_SEASON',
  // 版型
  CLOTHING_MODEL = 'CLOTHING_MODEL',
  // 厚薄
  THICKNESS = 'THICKNESS',
  // 图案
  PATTERN = 'PATTERN',
  // 面料材质
  FABRIC = 'FABRIC',
  /* 店铺类型 */
  STORE_TYPE = 'STORE_TYPE',
  /* 店铺-国内 */
  STORE_NAME_DOMESTIC = 'STORE_NAME_DOMESTIC',
  /* 品牌-国内 */
  BRAND_NAME_DOMESTIC = 'BRAND_NAME_DOMESTIC',
  /* 二级品类 */
  SECONDARY_CATEGORY = 'SECONDARY_CATEGORY',
}

export enum DPK_CUSTOM_DICTIONARY_KEY{
  /* 店铺 */
  STORE_NAME = 'STORE_NAME',
  /* 分类 */
  CATEGORY = 'CATEGORY',
  /* 商品状态 */
  COMMODITY_STATUS='COMMODITY_STATUS',
}

export enum MP_DICTIONARY_KEY{
  /* 妙款-品牌-品牌类型 */
  MOKEN_BRAND_TYPE = 'MOKEN_BRAND_TYPE',
  /* 妙款-品牌-品牌地区 */
  MOKEN_BRAND_AREA = 'MOKEN_BRAND_AREA',
  /* 妙款-品牌-品牌名称 */
  MOKEN_BRAND_NAME = 'MOKEN_BRAND_NAME',

  /* 妙款-品牌-城市 */
  MOKEN_BRAND_CITY = 'MOKEN_BRAND_CITY',
  /* 妙款-品牌-年份季节 */
  YEAR_SEASON = 'YEAR_SEASON',
  // 品类-SPU
  CATEGORY_SPU = 'CATEGORY_SPU',
  // /* 妙款-品牌-品牌名称 */
  // MOKEN_BRAND_NAME = 'MOKEN_BRAND_NAME',
  // /* 妙款-品牌-品牌名称 */
  // MOKEN_BRAND_NAME = 'MOKEN_BRAND_NAME',
  // /* 妙款-品牌-品牌名称 */
  // MOKEN_BRAND_NAME = 'MOKEN_BRAND_NAME',
  // /* 妙款-品牌-品牌名称 */
  // MOKEN_BRAND_NAME = 'MOKEN_BRAND_NAME',

}

// 字典启用禁用
export enum DICTIONARY_STATUS_ENUM{
  /* 启用 */
  ENABLE = '1',
  /* 禁用 */
  DISABLE = '0',
}
