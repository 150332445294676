import { ref, computed, nextTick, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { cloneDeep } from 'lodash-es';
import {
  IParams,
  ISortParams,
  ISearchKeys,
  IFilterTagsItem,
  ICustomParams, IGoodsItem,
} from '../type.d';
import {
  MK_SORT_WORD_LIST,
  SORT_TYPE_ENUM,
  SORT_WORD_ENUM,
  SEARCH_BASE_SETTING_ENUM,
} from '@/modules/miaokuan-manage/common/constant';
import { usePagination } from './use-pagination';
import { trackingExposure } from '@/utils/tracking';
import dayjs from 'dayjs';
import { IDictionaryItem, IFlatDictionaryItem } from '@/store/dictionary/types';
import { MP_DICTIONARY_KEY } from '@/constant/dictionary';
import { useDictionaryStore } from '../dictionary';
import { getBrandDetailInfo, getBrandDetailList } from '@/modules/miaokuan-manage/brand/api';
import {
  IBrandBrandDetailPageAttrsItem,
  IBrandFindMkbrandDetailRes,
} from '@/modules/miaokuan-manage/brand/api/type.d';
import {
  BRAND_TAB_ENUMS,
  BRAND_TAB_LIST,
} from '@/modules/miaokuan-manage/brand/constant';
import { commonfuns } from 'cx-utils';

/* 支持 列表查询 */

export default (
  currentTab:Ref<BRAND_TAB_ENUMS>,
  categoryAttrs: Ref<IDictionaryItem[]>,
) => {
  const route = useRoute();
  const brandName = route.query.brandName as string;

  // 扁平化字典
  const flatAttrsOptions = computed(() => {
    const deep = (
      arr: IDictionaryItem[],
      result: IFlatDictionaryItem[],
      pIds: string[],
      cIds: string[],
      lastLevelChildIds: string[],
    ) => {
      arr.forEach((v) => {
        const currentChildIds: string[] = [];
        const currentLastLevelChildIds: string[] = [];
        if (v.children && v.children.length) {
          deep(v.children, result, [...pIds, v.value], currentChildIds, currentLastLevelChildIds);
        } else {
          lastLevelChildIds.push(v.value);
        }
        cIds.push(v.value, ...currentChildIds);
        lastLevelChildIds.push(...currentLastLevelChildIds);
        result.push({
          ...v,
          pIds: [...pIds],
          cIds: [...currentChildIds],
          lastLevelChildIds: [...currentLastLevelChildIds],
        });
      });
    };
    const result:IFlatDictionaryItem[] = [];
    deep(categoryAttrs.value, result, [], [], []);
    return result;
  });
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const dictionaryStore = useDictionaryStore();
  const FLAT_DESIGN_CATEGORY = computed(() => dictionaryStore.flatCxDictionary(MP_DICTIONARY_KEY.CATEGORY_SPU));
  const defaultSearchKeys = ref<Array<ISearchKeys>>([]);
  const sortConfig = ref<ISortParams>({
    prop: SORT_WORD_ENUM.LATELY,
    order: SORT_TYPE_ENUM.DOWN,
  });
  const total = ref(0);
  const baseParams: ICustomParams = {
    brandName: '',
    city: '',
    categoryClass: [],
    categoryAttr: [],
    attrs: [],
    yearSeason: [],
    publishTime: [],
  };
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const searchType = ref<'default'|'advanced'>('default');
  const finish = ref(false);
  const goodsList = ref<IGoodsItem[]>([]);
  const getDetailInfo = async ():Promise<IBrandFindMkbrandDetailRes> => {
    try {
      const { data } = await getBrandDetailInfo({
        brandName,
      });
      return data as unknown as IBrandFindMkbrandDetailRes;
    } catch (e) {
      console.log('获取博主详情 error', e);
      return Promise.reject(e);
    }
  };
  const formatParams = (row: ICustomParams) => {
    const data:IParams = {
      // ...row,
    };
    if (row.categoryClass?.length) {
      let ids: string[] = [];
      row.categoryClass.forEach((v) => {
        ids.push(...v);
      });
      ids = [...new Set([...ids])];
      const categoryClass1: string[] = [];
      const categoryClass2: string[] = [];
      const categoryClass3: string[] = [];
      ids.forEach((id) => {
        const current = FLAT_DESIGN_CATEGORY.value.find(v => v.value === id);
        if (current) {
          const set = new Set([...current.lastLevelChildIds, ...ids]);
          if (set.size === ids.length) {
            const index = current.pIds.length;
            if (index === 0) {
              categoryClass1.push(id);
            } else if (index === 1) {
              categoryClass2.push(id);
            } else if (index === 2) {
              categoryClass3.push(id);
            }
          }
        }
      });
      data.categoryClass1 = categoryClass1;
      data.categoryClass2 = categoryClass2;
      data.categoryClass3 = categoryClass3;
      delete row.categoryClass;
    }
    if (row.categoryAttr?.length) {
      const ids:IBrandBrandDetailPageAttrsItem[] = [];
      row.categoryAttr.forEach((attr) => {
        const idx = ids.findIndex(item => item.field === attr[0]);
        if (idx > -1) {
          ids[idx].values.push(attr[1]);
        } else {
          ids.push({
            field: attr[0],
            values: [attr[1]],
          });
        }
      });
      data.attrs = ids;
      delete row.categoryAttr;
    }
    // data.startDate = '';
    // data.endDate = '';
    if (row.publishTime?.length === 2) {
      [data.startDate = '', data.endDate = ''] = row.publishTime;
    }
    delete row.publishTime;
    return { ...row, ...data };
  };
  const getList = async (current: number) => {
    try {
      const res = await getBrandDetailList({
        ...formatParams(cloneDeep(params.value)),
        channelId: currentTab.value,
        brandName,
        pageNum: current,
        pageSize: pageSize.value,
        ...sortConfig.value,
      });

      const newList = res.data?.list || [];
      goodsList.value.push(...newList);
      const moduleId = (BRAND_TAB_LIST.find(item => item.value === currentTab.value)?.moduleId) || '0';
      trackingExposure(newList, 'id', {
        moduleId,
      });
      total.value = Number(res.data.total);
      if (Number(res.data.total) <= reqPageIndex.value * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    if (currentTab.value !== BRAND_TAB_ENUMS.NONE) {
      reqPageIndex.value = 1;
      goodsList.value = [];
      getList(reqPageIndex.value);
    }
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    sortConfig.value.prop = val;
    sortConfig.value.order = sortConfig.value.order === SORT_TYPE_ENUM.DOWN
      ? SORT_TYPE_ENUM.TOP : SORT_TYPE_ENUM.DOWN;
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getBrandDetailList({
            ...cloneDeep(params.value),
            // channelId,
            channelId: currentTab.value,
            brandName,
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            ...sortConfig.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IGoodsItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        goodsList.value.push(...list);
        const moduleId = (BRAND_TAB_LIST.find(item => item.value === currentTab.value)?.moduleId) || '0';
        trackingExposure(list, 'spu', {
          moduleId,
        });
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
        //
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !goodsList.value.length && !listLoading.value;
  });

  const tagSortList = ref<Set<ISearchKeys>>(new Set([]));
  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.CITY:
          if (commonfuns.isEmpty(value)) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '城市',
            key,
            content: value,
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.CATEGORY: {
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          const text = value.map((item: string[]) => {
            const cates = item.map((id) => {
              const row = FLAT_DESIGN_CATEGORY.value.find(it => it.value === id);
              return row?.label || '';
            });
            return cates.join('|');
          }).join('、');
          tags.push({
            label: '品类',
            key,
            content: text,
          });
          tagSortList.value.add(key);
          break;
        }
        case SEARCH_BASE_SETTING_ENUM.ATTRS: {
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          const text = value.map((item: string[]) => {
            const cates = item.map((id) => {
              const row = flatAttrsOptions.value.find(it => it.value === id);
              return row?.label || '';
            });
            return cates.join('|');
          }).join('、');
          tags.push({
            label: '属性',
            key,
            content: text,
          });
          tagSortList.value.add(key);
          break;
        }
        case SEARCH_BASE_SETTING_ENUM.YEAR_SEASON:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '年份季节',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.PUBLISH_TIME:
          if (value.length === 2) {
            const [
              sDate,
              eDate,
            ] = [
              dayjs(value[0]).format('YYYY-MM-DD'),
              dayjs(value[1]).format('YYYY-MM-DD'),
            ];
            tags.push({
              label: '发布时间',
              key,
              content: `${sDate}-${eDate}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const sortFiltersTags = computed(() => {
    return [...tagSortList.value].map((v) => {
      return filtersTags.value.find(it => it.key === v);
    });
  });
  const handleRemoveFilterItem = (
    key: ISearchKeys,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.CITY:
        params.value[key] = '';
        break;
      case SEARCH_BASE_SETTING_ENUM.ATTRS:
      case SEARCH_BASE_SETTING_ENUM.YEAR_SEASON:
      case SEARCH_BASE_SETTING_ENUM.PUBLISH_TIME:
        params.value[key] = [];
        break;
      case SEARCH_BASE_SETTING_ENUM.CATEGORY:
        params.value[key] = [];
        params.value.categoryAttr = [];
        break;
      default:
        break;
    }
  };
  const handleReset = () => {
    sortConfig.value = {
      prop: SORT_WORD_ENUM.LATELY,
      order: SORT_TYPE_ENUM.DOWN,
    };
    params.value = cloneDeep(baseParams);
  };
  watch(() => filtersTags.value, () => {
    reload();
  });
  return {
    sortFiltersTags,
    handleRemoveFilterItem,
    handleReset,
    getDetailInfo,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    defaultSearchKeys,
    searchType,
    baseParams,
    handleChangeSort,
    sortConfig,
    sortList: MK_SORT_WORD_LIST,
    params,
    goodsList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
