
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import { processRouterQueryParams } from '@/utils/';
import useBrandList from './composables/use-list';
import { useSearchConfig } from './composables/use-search-config';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
import type { IDesignTaskSelectProductPictureReq } from '@/modules/miaokuan-manage/common/api/type.d';
import type { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { SORT_TYPE_ENUM } from '@/modules/doupinku-manage/constant';
import { confirmImage } from '@/modules/miaokuan-manage/common/api';
// import slsLogger from '@/core/plugins/slsLogger/SLSLogger';
// import { EVENT_TYPE_ENUM } from '@/core/plugins/slsLogger/constant';
import { ElMessage } from 'element-plus';
import NumberRangeInput from '@/modules/miaokuan-manage/common/components/number-range-input';
import SelectImgDialog from '@/modules/miaokuan-manage/common/components/select-img-dialog/index.vue';
import GoodItem from './components/good-item/index.vue';
import IconArrowDefault from '@/assets/images/resource/i_arrow_default.svg';
import IconArrowDown from '@/assets/images/resource/i_arrow_down.svg';
import IconArrowTop from '@/assets/images/resource/i_arrow_top.svg';
import { SUB_STATUS_ENUMS } from '@/modules/miaokuan-manage/common/constant';
import CancelSub from '@/modules/miaokuan-manage/common/components/cancel-sub/index.vue';
import Tabs from '@/components/tabs/index';
import { BRAND_CHANNEL_TYPE_LIST, BRAND_TAB_ENUMS, BRAND_TAB_LIST } from '@/modules/miaokuan-manage/brand/constant';
import { useCommon } from './composables/use-common';
import { IBrandFindMkbrandDetailRes } from '@/modules/miaokuan-manage/brand/api/type.d';
import { IGoodsItem } from './type.d';
import { $filters } from '@/core/plugins/filter';
import { IBrandSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';
import { postBrandSubscribe } from '@/modules/miaokuan-manage/sub/api';
import IconDesc from '@/assets/images/brand/icon-desc.png';
import usePermissionConfig from './hooks/use-permission-config';

export default defineComponent({
  components: {
    GoodItem,
    SelectImgDialog,
    NumberRangeInput,
    CancelSub,
    Tabs,
  },
  setup() {
    const router = useRouter();
    const goodsEl = ref<null|HTMLElement>(null);
    // const defaultSearchEl = ref<InstanceType<typeof ElForm> | null>(null);

    const detailData = reactive<{brandDetail:IBrandFindMkbrandDetailRes;}>({
      brandDetail: {
        id: '', // brandId
        brandName: '',
        brandZhName: '',
        brandLogo: '',
        brandIntroduce: '',
        showDate: '',
        latelyUpdatedTime: '',
        alreadySubscribe: true,
      },
    });

    /**
     * 特殊属性
     */
    const {
      categoryAttrOptions,
      getCategoryAttrOptions,
      // categoryOptions,
      // fetchCategoryOptions,
    } = useCommon();

    const permissionConfig = usePermissionConfig();

    const tabList = computed(() => {
      const list = BRAND_TAB_LIST.filter((item) => {
        switch (item.value) {
          case BRAND_TAB_ENUMS.SHOW:
            return permissionConfig.SHOW.value;
          case BRAND_TAB_ENUMS.TB:
            return permissionConfig.TB.value;
          case BRAND_TAB_ENUMS.JD:
            return permissionConfig.JD.value;
          case BRAND_TAB_ENUMS.WPH:
            return permissionConfig.WPH.value;
          case BRAND_TAB_ENUMS.INS:
            return permissionConfig.INS.value;
          default:
            return false;
        }
      });
      return list;
    });
    const currentTab = ref<BRAND_TAB_ENUMS>(tabList?.value?.[0]?.value ?? BRAND_TAB_ENUMS.NONE);

    const descDialogConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: [] },
    );
    const handleDescClose = () => {
      descDialogConfig.value.visible = false;
    };
    const openDescDialog = () => {
      descDialogConfig.value.visible = true;
    };

    // 列表查询
    const {
      // reload,
      sortFiltersTags,
      handleRemoveFilterItem,
      handleReset,
      getDetailInfo,
      handleCurrentSizeChange,
      // defaultSearchKeys,
      // searchType,
      // baseParams,
      params,
      goodsList,
      // reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
    } = useBrandList(currentTab, categoryAttrOptions);

    const handleTabChange = () => {
      handleReset();
      // reload();
    };

    // 字典和搜索项el
    const {
      searchConfig,
      getOptions,
    } = useSearchConfig(params, currentTab, categoryAttrOptions);
    getOptions();

    // 选图-设计任务
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();
    watch(() => params.value.categoryClass, (n) => {
      if (n && n.length === 1) {
        const ary = n[0];
        getCategoryAttrOptions(n[0][ary.length - 1]);
        return;
      }
      categoryAttrOptions.value = [];
    });

    // 选图｜快速选图
    const selectImgConfig = ref<{data:IDesignTaskSelectProductPictureReq;visible:boolean;}>(
      {
        data: { id: '', channelId: '', categoryClass: '' },
        visible: false,
      },
    );
    const handleSelectImgItem = async (param:IDesignTaskSelectProductPictureReq, type: 'quick' | 'normal') => {
      const { id = '', key = '' } = param;
      // let eventDetails: { [key: string]: string; } = {
      //   eventName: '选图',
      //   goodId: key || '',
      // };
      // if (type === 'quick') {
      //   eventDetails = {
      //     ...eventDetails,
      //     eventName: '快速选图',
      //     taskId: id || '',
      //   };
      // }
      // slsLogger.send({
      //   eventType: EVENT_TYPE_ENUM.CLICK,
      //   eventDetails,
      // });
      if (type === 'quick' && id) {
        await confirmImage(param);
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        return;
      }
      selectImgConfig.value = { data: param, visible: true };
    };

    // 点击图片跳转详情
    const handleImageItem = (row: IGoodsItem) => {
      // slsLogger.send({
      //   eventType: EVENT_TYPE_ENUM.CLICK,
      //   eventDetails: {
      //     eventName: '查看图片详情',
      //     goodId: row.postId,
      //   },
      // });
      const compName = $filters.getEnumLabel(BRAND_CHANNEL_TYPE_LIST, row.channelId);
      const newPage = router.resolve({
        name: `BrandDetail${compName}`,
        query: {
          // name: compName,
          brandName: row.brandName,
          id: row.id,
        },
      });
      window.open(newPage.href);
    };

    const status = ref<SUB_STATUS_ENUMS>(SUB_STATUS_ENUMS.SUB);

    // 订阅
    const handleSub = async () => {
      // slsLogger.send({
      //   eventType: EVENT_TYPE_ENUM.CLICK,
      //   eventDetails: {
      //     eventName: '订阅',
      //     targetId: detailData.brandDetail.poId,
      //   },
      // });
      const param:IBrandSubscribeReq = {
        brandId: detailData.brandDetail.id as string,
        isSubscribe: SUB_STATUS_ENUMS.SUB,
      };
      await postBrandSubscribe(param);
      ElMessage.success('订阅成功');
      status.value = SUB_STATUS_ENUMS.SUB;
    };
    // 取消订阅
    const cancelSubConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: [] },
    );
    const handleSubCancel = async () => {
      // slsLogger.send({
      //   eventType: EVENT_TYPE_ENUM.CLICK,
      //   eventDetails: {
      //     eventName: '取消订阅弹窗-确定',
      //     targetId: detailData.brandDetail.poId,
      //   },
      // });
      const param:IBrandSubscribeReq = {
        brandId: detailData.brandDetail.id as string,
        isSubscribe: SUB_STATUS_ENUMS.CANCEL,
      };
      await postBrandSubscribe(param);
      ElMessage.success('取消订阅成功');
      cancelSubConfig.value.visible = false;
      status.value = SUB_STATUS_ENUMS.CANCEL;
    };
    const openCancelSub = () => {
      // slsLogger.send({
      //   eventType: EVENT_TYPE_ENUM.CLICK,
      //   eventDetails: {
      //     eventName: '取消订阅',
      //     targetId: detailData.brandDetail.poId,
      //   },
      // });
      cancelSubConfig.value.visible = true;
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      // slsLogger.send({
      //   eventType: EVENT_TYPE_ENUM.CLICK,
      //   eventDetails: {
      //     eventName: '选图弹窗-点击确定',
      //     goodId: selectImgConfig.value.data.key,
      //     taskId: id,
      //   },
      // });
    };

    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      urlParams.pageNum = 0;
      params.value = urlParams;
      detailData.brandDetail = (await getDetailInfo());
      status.value = SUB_STATUS_ENUMS[detailData.brandDetail?.alreadySubscribe ? 'SUB' : 'CANCEL'];
      initPagination(goodsEl.value!);
      // reload();
    };

    onMounted(() => {
      init();
      // fetchCategoryOptions();
    });

    return {
      descDialogConfig,
      handleDescClose,
      openDescDialog,
      categoryAttrOptions,
      tabList,
      currentTab,
      handleTabChange,
      sortFiltersTags,
      searchConfig,
      handleRemoveFilterItem,
      handleReset,
      ...toRefs(detailData),
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      // defaultSearchEl,
      // defaultSearchKeys,
      // defaultSearchConfig,
      handleChangeSort,
      sortList,
      sortConfig,
      designTaskOptions,
      handleSelectImgItem,
      selectImgConfig,
      goodsList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      handleImageItem,
      status,
      handleSub,
      handleSubCancel,
      cancelSubConfig,
      openCancelSub,
      handleSelectSuccess,
      IconDesc,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <div class="brand-header">
            <div class="header-info flex flex-justify-between">
              <div class='left'>
                <div class="left-img">
                  <custom-image
                    style="width: 72px; height: 72px;border-radius: 50%"
                    src={`${this.brandDetail?.brandLogo}`}
                    fit="cover"
                  />
                </div>
                <div class="left-basic">
                  <strong>
                    <span title={this.brandDetail?.brandName}>
                    {this.brandDetail?.brandName}
                  </span>
                    <span class="margin-left-5" title={this.brandDetail?.brandZhName}>
                      {this.brandDetail?.brandZhName}
                    </span>
                  </strong>
                  <div class="left-introduction" onClick={this.openDescDialog}>
                    <img src={IconDesc} class="desc_icon" />
                    <span class="intro-btn" v-show={this.brandDetail?.brandIntroduce}>品牌简介</span>
                    <el-dialog
                      title= "品牌简介"
                      modelValue={this.descDialogConfig.visible}
                      onClose={this.handleDescClose}
                      top="30vh"
                      center
                      close-on-click-modal={false}
                      close-on-press-escape={false}
                      show-close={true}
                      width="600px"
                      custom-class="el-dialog-inner-scroll"
                    >
                      <section class="intro-info">
                        <p class="intro-name">{this.brandDetail?.brandName}</p>
                        <span class="intro-text">
                          {this.brandDetail?.brandIntroduce}
                        </span>
                      </section>
                    </el-dialog>
                  </div>
              </div>
              </div>
              <div class='right'>
                <div>
                  <el-button
                    type={this.status === SUB_STATUS_ENUMS.CANCEL ? 'primary' : 'default'}
                    icon={this.status === SUB_STATUS_ENUMS.CANCEL ? 'el-icon-plus' : ''}
                    onClick={this.status === SUB_STATUS_ENUMS.CANCEL ? this.handleSub : this.openCancelSub}
                  >{this.status === SUB_STATUS_ENUMS.CANCEL ? '订阅' : '取消订阅'} </el-button>
                </div>
              </div>
            </div>
            <div class="tabs-container">
              <tabs
                lineHeight={30}
                fontSize={16}
                border={false}
                config={this.tabList}
                v-model={this.currentTab}
                onChange={this.handleTabChange}
              />
            </div>
          </div>
          <filter-bar
            tags={this.sortFiltersTags}
            searchConfig={this.searchConfig}
            onReset={this.handleReset}
            onRemoveItem={this.handleRemoveFilterItem}
          />
          <div class="sorting-content">
            {this.sortList.map(item => (
              <div
                key={item.value}
                class="item"
                onClick={() => this.handleChangeSort(item.value)}
              >
                <div>{item.label}</div>
                <div class="arrow">
                  <img src={IconArrowDefault} class="arrow_icon" />
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.TOP
                  && <img src={IconArrowTop} class="arrow_icon" />
                  }
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.DOWN
                  && <img src={IconArrowDown} class="arrow_icon" />
                  }
                </div>
              </div>
            ))}
          </div>
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.goodsList.map((v, i) => (
                <good-item
                  key={i}
                  data={v}
                  currentTab={this.currentTab}
                  onSelectImg={this.handleSelectImgItem}
                  onImage={this.handleImageItem}
                />
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <select-img-dialog
          v-model={[this.selectImgConfig.visible, 'visible']}
          data={this.selectImgConfig.data}
          options={this.designTaskOptions}
          onSuccess={this.handleSelectSuccess}
        />
        <cancel-sub v-model={[this.cancelSubConfig.visible, 'visible']} onSubCancel={this.handleSubCancel}/>
      </div>
    );
  },
});
