// 详情页tab选项
export enum BRAND_TAB_ENUMS {
  /* 秀场 */
  SHOW = '',
  /* 淘宝 */
  TB = '4',
  /* 京东 */
  JD = '12',
  /* 唯品会 */
  WPH = '13',
  /* INS */
  INS='6',
  NONE='999'
}

export const BRAND_TAB_LIST = [
  { value: BRAND_TAB_ENUMS.SHOW, label: '秀场', compName: 'Show', moduleId: '0' },
  { value: BRAND_TAB_ENUMS.TB, label: '淘宝', compName: 'TaoBao', moduleId: '1' },
  { value: BRAND_TAB_ENUMS.JD, label: '京东', compName: 'JingDong', moduleId: '2' },
  { value: BRAND_TAB_ENUMS.WPH, label: '唯品会', compName: 'WeiPin', moduleId: '3' },
  { value: BRAND_TAB_ENUMS.INS, label: 'INS', compName: 'Ins', moduleId: '4' },
];

// 详情页tab选项
export enum BRAND_CHANNEL_TYPE_ENUMS {
  /* 淘宝 */
  TB = '4',
  /* 京东 */
  JD = '12',
  /* 唯品会 */
  WPH = '13',
  /* INS */
  INS='6',
  /* 秀场-vogui */
  SHOW_VOGUE = '9',
  /* 秀场-新浪时尚 */
  SHOW_SINA_FASHION = '10',
  /* 秀场-fashionNetwork */
  SHOW_FASHION_NETWORK = '11',
}

export const BRAND_CHANNEL_TYPE_LIST = [
  { value: BRAND_CHANNEL_TYPE_ENUMS.TB, label: 'TaoBao' },
  { value: BRAND_CHANNEL_TYPE_ENUMS.JD, label: 'JingDong' },
  { value: BRAND_CHANNEL_TYPE_ENUMS.WPH, label: 'WeiPin' },
  { value: BRAND_CHANNEL_TYPE_ENUMS.INS, label: 'Ins' },
  { value: BRAND_CHANNEL_TYPE_ENUMS.SHOW_VOGUE, label: 'Show' },
  { value: BRAND_CHANNEL_TYPE_ENUMS.SHOW_SINA_FASHION, label: 'Show' },
  { value: BRAND_CHANNEL_TYPE_ENUMS.SHOW_FASHION_NETWORK, label: 'Show' },
];

export enum QUERY_SEARCH_TYPE {
  /* 妙款-品牌-品牌类型 */
  MOKEN_BRAND_TYPE = 'moken_brand_type',
  /* 妙款-品牌-品牌地区 */
  MOKEN_BRAND_AREA = 'moken_brand_area',
  /* 妙款-品牌-品牌名称 */
  MOKEN_BRAND_NAME = 'moken_brand_name',
  /* 品类 */
  CATEGORY = '7',
  /* 年份季节 */
  YEAR_SEASON = 'year_season',
}

// 获取下拉选项的枚举
export enum OPTION_ENUM {
  /* 渠道类型 */
  CHANNEL_TYPE = '1',
  /* 店铺类型 */
  SHOP_TYPE = '2',
  /* 二级分类 */
  CATEGORY_TYPE = '7',
  /* 淘品库-店铺 */
  STORE_NAME_DOMESTIC = '6',
  /* 淘品库-品牌 */
  BRAND_NAME_DOMESTIC = '4',
  /* 二级分类 */
  SECONDARY_CATEGORY = '7',
}
